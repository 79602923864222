@import url(https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media (min-width: 768px) and (orientation: landscape) {
  .MuiToolbar-regular {
    min-height: 64px !important;
  }
}

body {
  overflow: hidden;
}

.text-highlight--light {
  background-color: #FFF9C4;
}

.text-highlight--dark {
  background-color: #6d6840;
}

/* Entity show page transition styles */

.show-page-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.show-page-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transition: opacity 180ms, -webkit-transform 180ms;
  transition: opacity 180ms, transform 180ms;
  transition: opacity 180ms, transform 180ms, -webkit-transform 180ms;
}

.show-page-appear {
  opacity: 0.7;
}

.show-page-appear-active {
  opacity: 1;
  transition: opacity 150ms;
}

.show-page-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.show-page-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transition: opacity 180ms, -webkit-transform 180ms;
  transition: opacity 180ms, transform 180ms;
  transition: opacity 180ms, transform 180ms, -webkit-transform 180ms;
}

.show-page-exit-done {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  display: none;
}

/* Entity edit page transition styles */

.edit-page-enter {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.edit-page-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transition: opacity 180ms, -webkit-transform 180ms;
  transition: opacity 180ms, transform 180ms;
  transition: opacity 180ms, transform 180ms, -webkit-transform 180ms;
}

.edit-page-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.edit-page-exit-active {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transition: opacity 150ms, -webkit-transform 150ms;
  transition: opacity 150ms, transform 150ms;
  transition: opacity 150ms, transform 150ms, -webkit-transform 150ms;
}

.edit-page-exit-done {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  display: none;
}
