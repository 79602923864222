@media (min-width: 768px) and (orientation: landscape) {
  .MuiToolbar-regular {
    min-height: 64px !important;
  }
}

body {
  overflow: hidden;
}

.text-highlight--light {
  background-color: #FFF9C4;
}

.text-highlight--dark {
  background-color: #6d6840;
}

/* Entity show page transition styles */

.show-page-enter {
  opacity: 0;
  transform: scale(0.9);
  transform-origin: center center;
}

.show-page-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
  transition: opacity 180ms, transform 180ms;
}

.show-page-appear {
  opacity: 0.7;
}

.show-page-appear-active {
  opacity: 1;
  transition: opacity 150ms;
}

.show-page-exit {
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
}

.show-page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transform-origin: center center;
  transition: opacity 180ms, transform 180ms;
}

.show-page-exit-done {
  opacity: 0;
  transform: scale(0.9);
  transform-origin: center center;
  display: none;
}

/* Entity edit page transition styles */

.edit-page-enter {
  opacity: 0;
  transform: scale(1.1);
  transform-origin: center center;
}

.edit-page-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
  transition: opacity 180ms, transform 180ms;
}

.edit-page-exit {
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
}

.edit-page-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transform-origin: center center;
  transition: opacity 150ms, transform 150ms;
}

.edit-page-exit-done {
  opacity: 0;
  transform: scale(1.1);
  transform-origin: center center;
  display: none;
}